button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
}

.tree-wrapper {
  display: flex;
  flex-direction: column;
}

.indent-10 {
  padding-left: 10px;
}

.closed {
  display: none;
}

.tooltip {
  background: black;
  color: white;
  font-size: 2rem;
  border-radius: 3px;
  position: absolute;
  left: 20px;
}

.tooltip-button {
  cursor: pointer;
}

.edit-window {
  display: grid;
  grid-template-columns: 100px 100px;
  grid-gap: 10px;
  background: #edf2f7;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
}

.fs15 {
  color: #4a5568;
  font-size: 15px;
}